import { load, type ReCaptchaInstance } from 'recaptcha-v3'
import type { IReCaptchaLoaderOptions } from 'recaptcha-v3/dist/ReCaptchaLoader'
import { useRuntimeConfig } from '#imports'

export const useRecaptcha = () => {
  const options: IReCaptchaLoaderOptions = {
    // see options details: https://github.com/abinnovision/recaptcha-v3
    useRecaptchaNet: true,
    autoHideBadge: true,
    explicitRenderParameters: {
      badge: 'bottomright',
    },
  }

  const config = useRuntimeConfig()

  let recaptcha: ReCaptchaInstance | undefined = undefined

  async function initRecaptcha() {
    recaptcha = await load(config.public.recaptcha.siteKey, options)
  }

  async function executeRecaptcha(action: string) {
    // get the token, a custom action could be added as argument to the method
    if (!recaptcha) throw new Error('ReCaptchaInstance undefined')
    const token = await recaptcha.execute(action)
    return token
  }

  return {
    initRecaptcha,
    executeRecaptcha,
  }
}
